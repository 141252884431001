.nav-wrp{
    height: 72px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    padding: 0 70px 0 100px;
    margin-bottom: 40px;
    position: fixed;
    width: 100%;
    z-index: 3;
    min-width: 1380px;
    .logo{
        width: 70px;
        height: 72px;
        float: left;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: auto 28px;
        background-image: url('../icon/logo.png');
    }
    ul{
        float: left;
        li{
            font-size: 18px;
            font-family: SF Pro Text-Regular;
            font-weight: 400;
            color: #A1A4B3;
            margin-right: 40px;
            line-height: 72px;
            cursor: pointer;
            position: relative;
            float: left;
            &.active{
                color: #167639;
            }
            &.active::after{
                content: '';
                display: block;
                background-color: #167639;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 80%;
                height: 2px;
            }
        }
    }
    .out{
        font-size: 18px;
        font-family: SF Pro Text-Regular, SF Pro Text;
        font-weight: 400;
        color: #167639;
        float: right;
        line-height: 72px;
        cursor: pointer;
    }
}
.btm{
    padding-top: 112px;
}