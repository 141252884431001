.boxs-wrap{
    width: 100%;
    box-sizing: border-box;
    padding: 0 130px 50px 130px;
    h1{
        font-size: 30px;
        font-family: SF Pro Text-Medium;
        font-weight: 500;
        color: #393B3E;
        line-height: 35px;
        margin-bottom: 20px; 
        box-sizing: border-box;
        .slt{
            float: right;
            width: 180px;
        }
        .date-wrp{
            float: right;
            margin-right: 20px;
            .ant-picker{
                width: 280px;
                height: 48px;
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                border: 1px solid #393B3E;
                box-shadow: none;
                outline: none;
                padding: 4px 20px 4px 20px;
            }
            .ant-picker-suffix{
                width: 20px;
                height: 20px;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 20px auto;
                background-image: url('../icon/cal.png');        
                span{
                    opacity: 0;
                }
            }
            .ant-picker-range .ant-picker-active-bar{
                display: none;
            }
            .ant-picker-input > input{
                font-size: 16px;
                font-family: SF Pro Text-Regular;
                font-weight: 400;
                line-height: 46px;
            }
            .ant-picker-range .ant-picker-clear {
                right: 3px;
            }
            .ant-picker-range-separator{
                padding: 0;
            }
        }
    }
    .cloumn{
        display: flex;
        flex-direction: row;
        .box{
            flex: 1;
            margin-right: 30px;
            margin-bottom: 30px;
            &:nth-last-child(1){
                margin-right: 0;
            }
        }
    }
    .cloumn-bottom{
        .pad-rgt{
            width: 33.3%;
            float: left;
            padding-right: 30px;
            margin-bottom: 40px;
        }
    }
    .mar-btm-40{
        margin-bottom: 40px;
    }
    .box{
        box-sizing: border-box;
        padding: 15px 20px 20px 20px;
        background: #F8F8FA;
        border-radius: 8px;
        h2{
            font-size: 16px;
            font-family: SF Pro Text-Regular;
            font-weight: 400;
            color: #A1A4B3;
            line-height: 29px;
            word-break: break-word;
            margin-bottom: 5px;
        }
        p{
            font-size: 28px;
            font-family: SF Pro Text-Regular;
            font-weight: 400;
            color: #393B3E;
            line-height: 42px;
            margin-bottom: 0;
        }
    }
    .slt{
        .ant-select{
            width: 100%;
            .ant-select-selector{
                border-radius: 8px !important;
                border: 1px solid #393B3E !important;
                height: 48px !important;
                line-height: 48px !important;
                box-shadow: none !important;
            }
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
            line-height: 48px !important;
            color: #393B3E !important;
            font-size: 16px;
            font-family: SF Pro Text-Regular;
            font-weight: 400;
            color: #393B3E;
        }
        .ant-select-show-arrow .ant-select-arrow{
            width: 20px !important;
            height: 20px !important;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 18px auto;
            background-image: url('../icon/u.png');   
            top: 44%; 
            transform: rotate(180deg);
            .anticon{
                opacity: 0;
            }
        }
        .ant-select-open .ant-select-arrow{
            transform: none !important;
        }
    }
    .table{
        background: #FFFFFF;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 20px 0 30px 0;
        margin-bottom: 30px;
        h3{
            box-sizing: border-box;
            padding-left: 20px;
            margin-bottom: 10px;
            font-size: 20px;
            font-family: SF Pro Text-Medium, SF Pro Text;
            font-weight: 500;
            color: #393B3E;
            line-height: 23px;
        }
        .ant-table table{table-layout: fixed !important;}
        .ant-table-thead > tr > th{
            background: #fff !important;
            padding: 10px 20px !important;
            font-size: 12px !important;
            font-family: SF Pro Text-Medium !important;
            font-weight: 500 !important;
            color: #A1A4B3 !important;
            line-height: 16px !important;
            border-top: 1px solid #E5E5E5 !important;
            border-bottom: 1px solid #E5E5E5 !important;
        }
        .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
            display: none !important;
        }
        .ant-table-tbody > tr > td{
            padding: 10px 20px !important;
            border: none !important;
            word-break: break-word !important;
            font-size: 16px !important;
            font-family: SF Pro Text-Regular !important;
            font-weight: 400 !important;
            color: #393B3E !important;
            line-height: 28px !important;
            background-color: #fff !important;
        }
        .ant-table-tbody > tr:nth-child(1) td{
            padding-top: 25px !important;
        }
        .ant-table-tbody > tr:nth-child(2n) td{
            background-color: #F8F8FA !important;
        }
        .ant-table-column-sorters{
            display: block !important;
        }
        .ant-table-column-sorter{
            margin-left: 6px !important;
            color: #A1A4B3 !important;
            position: relative;
            top: -2px;
        }
        .ant-table-tbody > tr > .ant-table-row-expand-icon-cell{
            width: 48px !important;
            box-sizing: border-box !important;
            .ant-table-row-expand-icon{
                width: 20px;
                height: 20px;
                position: relative;
                left: -15px;
                top: -2px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 20px auto;
                border: 0 !important;
                &::after, &::before{
                    display: none;
                }
            }
            .ant-table-row-expand-icon-collapsed{
                background-image: url('../icon/p.png');
            }
            .ant-table-row-expand-icon-expanded{
                background-image: url('../icon/u.png');
        
            }
        }        
        .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{
            color: #393B3E !important;
        }
        .ant-table-tbody > .ant-table-expanded-row td{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .inner-conent{
            box-sizing: border-box;
            .line{
                display: flex;
                width: 100%;
                div{
                    flex: 1;
                    box-sizing: border-box;
                    padding: 10px 20px;
                }
                .last{
                    flex: 0 0 auto;
                    width: 48px;
                }
            }
        }
        
    }
}
