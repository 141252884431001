.bdg{
    width: 100%;
    height: 100vh;
    position: relative;
    background: #F4F4F4;
    .logo{
        height: 72px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.05);
        background-repeat: no-repeat;
        background-position: 70px center;
        background-size: auto 28px;
        background-image: url('../icon/logo.png');
    }
    .cont{
        width: 480px;
        height: 421px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.05);
        border-radius: 8px 8px 8px 8px;
        margin: 120px auto 0 auto;
        box-sizing: border-box;
        padding: 40px 20px 0 20px;
        h1{
            font-size: 24px;
            font-family: SF Pro Text-Semibold;
            font-weight: 600;
            color: #393B3E;
            line-height: 28px;
            padding-bottom: 30px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 20px;
        }
        .login-form{
            .ant-row{
                display: block !important;
            }
            .ant-form-vertical .ant-form-item-label{
                padding: 0 !important;
            }
            .ant-form-item{
                margin-bottom: 20px;
                height: 80px !important;
                .ant-form-item-label{
                    font-size: 16px;
                    font-family: SF Pro Text-Regular;
                    font-weight: 400;
                    color: #393B3E;
                    line-height: 22px;
                    margin-bottom: 10px;
                }
                .ant-input{
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 8px 8px 8px 8px;
                    border: 1px solid #D8D8D8;
                    width: 100%;
                    outline: none;
                    padding-left: 15px;
                    box-sizing: border-box;
                }
                .ant-input:hover{
                    border-color: #D8D8D8;
                }
            }
            .btn-login{
                width: 100%;
                margin-top: 10px;
                height: 48px;
                background: #167639;
                border-radius: 8px;
                font-size: 20px;
                font-family: SF Pro Text-Semibold;
                font-weight: 600;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
}
