ul{
  margin: 0 !important;
  padding: 0;
  list-style-type: none;
}
h1, h2, h3, h4, h5, p{
  margin: 0;
  padding: 0;
  word-break: break-word;
}
.clear:after{
  content: '';
  width: 0;
  height: 0;
  display: block;
  clear: both;
}
.App{
  // min-width: 1380px;
}
.page{
  padding-top: 50px;
  text-align: center;
  .ant-pagination-item{
      width: 40px !important;
      height: 40px !important;
      border-radius: 8px !important;
      border: 1px solid #D7E0E0 !important;
      margin-right: 6px !important;
      font-size: 16px !important;
      font-family: Founders Grotesk-Regular, Founders Grotesk !important;
      font-weight: 400 !important;
      color: #101010 !important;
      line-height: 40px !important;
      a:hover{color: #3D3DFF !important;}
  }
  .ant-pagination-item-active{
      border-color: #3D3DFF !important;
      a{color: #3D3DFF !important;}
  }
  .ant-pagination-next, .ant-pagination-prev{
      width: 40px !important;
      height: 40px !important;
      border-radius: 8px !important;
      border: 1px solid #758386 !important;
  }
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
      background-color: transparent !important;
      border: none !important;
      .anticon{
          display: none;
      }
  }
  .ant-pagination-disabled{
      width: 40px !important;
      height: 40px !important;
      border-radius: 8px !important;
      border: 1px solid #D5D6D7 !important;
  }
  .ant-pagination-next .ant-pagination-item-link{
    background-repeat: no-repeat;
    background-size: 14px auto;
    background-position: center center;
    background-image: url('./assets/icon/rl.png');
  }
  .ant-pagination-next.ant-pagination-disabled .ant-pagination-item-link{
    background-image: url('./assets/icon/rd.png')
  }
  .ant-pagination-prev .ant-pagination-item-link{
    background-repeat: no-repeat;
    background-size: 14px auto;
    background-position: center center;
    background-image: url('./assets/icon/ll.png');
  }
  .ant-pagination-prev.ant-pagination-disabled .ant-pagination-item-link{
    background-image: url('./assets/icon/ld.png')
  }
  .ant-pagination-options-quick-jumper{
    font-size: 16px;
    font-family: SF Pro Text-Regular, SF Pro Text;
    font-weight: 400;
    color: #393B3E;
    margin-left: 4px;
  }
}
.major-content{
.ant-switch{
  width: 57px !important;
  height: 33px !important;
  border-radius: 17px !important;
  background: #f0f0f0 !important;
  border: 1px solid black !important;
  .ant-switch-handle{
      width: 28px !important;
      height: 28px !important;
      left: 1px !important;
      right: auto !important;
      top: 1px !important;
  }
  .ant-switch-handle::before{
      top: 1px !important;
      right: 1px !important;
      border-radius: 14px !important;
      background: black !important;
  }

}
.ant-switch-checked{
  background: #9CE026 !important;
  border: 1px solid #62A113 !important;
  .ant-switch-handle{
      width: 28px !important;
      height: 28px !important;
      left: 26px !important;
      right: auto !important;
      top: 1px !important;
  }
  .ant-switch-handle::before{
      top: 1px !important;
      left: 1px !important;
      border-radius: 14px !important;
      background: #fff !important;
  }
}

}
.ant-modal-centered{
  min-width: 900px !important;
}
@media (max-width: 767px){
.ant-modal {
    max-width: 900px !important;
    margin: 8px auto;
}
}
.ant-form-item-has-error .ant-input{
  border-color: #ff4d4f !important;
}
.ant-drawer-header{
  border-bottom: none !important;
}
.ant-select-open .ant-select-arrow{
  transform: rotate(180deg);
}
.warn-border{
  border-color: #ff4d4f !important;
}
.ant-empty-normal{
  margin: 0 !important;
  display: none;
}
// 表单相关 start
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  display: none !important;
}
// 表格没数据的样式
.loading-content{
  text-align: center;
  font-size: 30px;
  padding-top: 30px;
}
.empty-content{
  font-family: Founders Grotesk-Regular, Founders Grotesk;
  font-weight: 400;
  color: #878787;
  text-align: center;
  padding-top: 30px;
  font-size: 16px;
}
// 右边弹出窗
.ant-drawer-header{
  padding: 50px 0 0 30px !important;
  .ant-drawer-title{
    font-size: 28px;
    font-family: Founders Grotesk-Medium, Founders Grotesk;
    font-weight: 500;
    color: #101010;
    line-height: 32px;
  }
  .ant-drawer-header-title{
    position: relative;
  }
}
.ant-drawer-content{
  overflow: visible !important;
}
.ant-drawer-open .ant-drawer-close{
  position: absolute;
  left: -98px;
  top: -20px;
  z-index: 10;
  background-repeat: no-repeat !important;
  background-size: 36px auto !important;
  background-position: center center !important;
  background-image: url('./assets/icon/c2.png') !important;
  width: 36px;
  height: 36px;
  .anticon{
    display: none;
  }
}
.ant-drawer-header{
  border-bottom: none !important;
}
.ant-drawer-body{
  padding: 30px !important;
}
.ant-drawer.ant-drawer-open .ant-drawer-mask{
  backdrop-filter: blur(5px) brightness(70%) !important;
}
.height100{
  height: 100px !important;
  textarea.ant-input, input{
    width: 100%;
    font-size: 16px;
    font-family: Founders Grotesk-Regular, Founders Grotesk;
    font-weight: 400;
    color: #101010;
    height: 48px;
    line-height: 48px;
    outline: none;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #A1A4B3;
    overflow: hidden;
    position: relative;
    padding: 0 60px 0 15px;
    box-shadow: none !important;
    transition: all 0.3s;
  }
  .red-border{
    border-color: #ff4d4f !important;
  }
  .pad-rgt-40{
    padding-right: 40px;
  }
}
.associate-input{
  textarea.ant-input, input{
    width: 100%;
    font-size: 16px;
    font-family: Founders Grotesk-Regular, Founders Grotesk;
    font-weight: 400;
    color: #101010;
    height: 48px;
    line-height: 48px;
    outline: none;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #A1A4B3;
    overflow: hidden;
    position: relative;
    padding: 0 60px 0 15px;
    box-shadow: none !important;
    transition: all 0.3s;
  }
  .red-border{
    border-color: #ff4d4f !important;
  }
  .pad-rgt-40{
    padding-right: 40px;
  }

}
.update-input{
  margin-bottom: 40px !important;
  textarea.ant-input, input{
    width: 100%;
    font-size: 16px;
    font-family: Founders Grotesk-Regular, Founders Grotesk;
    font-weight: 400;
    color: #101010;
    height: 200px !important;
    line-height: 30px;
    outline: none;
    height: 30px;
    border-radius: 12px;
    border: 1px solid #A1A4B3;
    position: relative;
    padding: 10px 15px 10px 15px;
    box-shadow: none !important;
    transition: all 0.3s;
    overflow-y: auto;
  }
}

.ant-input-textarea-show-count::after{
  margin: 0 !important;
  position: relative;
  top: -35px;
  left: -23px;
  font-size: 16px !important;
  font-family: SF Pro Text-Regular, SF Pro Text !important;
  font-weight: 400 !important;
  color: #393B3E !important;
}
.mar-btm-40{
  margin-bottom: 40px;
  position: relative;
  .percent-symbol{
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-family: SF Pro Text-Regular, SF Pro Text;
    font-weight: 400;
    color: #393B3E;
    top: 11px;
  }
}
.associate-wrap{
  .associate-btn{
    cursor: pointer;
    width: 95px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #A1A4B3;
    background-repeat: no-repeat;
    background-size: 24px auto;
    background-position: center center;
    background-image: url('./assets/icon/a.png');
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .associate-number{
    width: 95px;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    input{
      padding-right: 36px;
    }
  }  
  div{
    margin-bottom: 40px;
    &:nth-child(4n){
      margin-right: 0;
    }
  }
  .delete{
    position: absolute;
    width: 24px;
    height: 24px;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url('./assets/icon/clo.png');
  }
}

.modal-btns{
  text-align: center;
  .cancel-btn{
    width: 160px !important;
    height: 48px !important;
    border-radius: 28px !important;
    border: 1px solid #A1A4B3 !important;
    margin-right: 20px !important;
    font-size: 18px !important;
    font-family: SF Pro Text-Regular, SF Pro Text !important;
    font-weight: 400 !important;
    color: #A1A4B3 !important;
  }
  .ok-btn{
    width: 160px !important;
    height: 48px !important;
    background: #167639 !important;
    border-radius: 28px !important;
    font-size: 18px !important;
    border-color: #167639 !important;
    color: #fff !important;
  }

  .confirm-delete-btn{
    width: 160px !important;
    height: 48px !important;
    background: #E01C40 !important;
    border-radius: 28px !important;
    font-size: 18px !important;
    border-color: #E01C40 !important;
    color: #fff !important;
  }
}
.ant-tooltip{
  display: none !important;
}
.sort{
  .txt{
      position: relative;
      .up{
          display: block;
          position: absolute;
          right: -22px;
          top: -2px;
          font-size: 12px;
          cursor: pointer;
          padding: 0 3px;
          height: 12px;
          line-height: 12px;
      }
      .dn{
          display: block;
          position: absolute;
          right: -22px;
          top: 7px;
          font-size: 12px;
          cursor: pointer;
          padding: 0px 3px;
          height: 12px;
          line-height: 12px;
      }
      .active1{
          color: #000;
      }
  }
}
