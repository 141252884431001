.delete-wrp {
    width: 100%;
    height: 100vh;
    position: relative;
    background: #F4F4F4;
    .logo {
        height: 72px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.05);
        background-repeat: no-repeat;
        background-position: 70px center;
        background-size: auto 28px;
        background-image: url('../icon/logo-text.png');
    }
    .container {
        height: calc(100vh - 72px);
        display: grid;
        place-items: center;
        .box {
            width: 480px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.05);
            border-radius: 8px 8px 8px 8px;
            box-sizing: border-box;
            padding: 40px 20px;
            transition: all .3s;
            .shrink {
                padding: 0 10px;
                box-sizing: border-box;
            }
            h1 {
                font-family: SF Pro Text, SF Pro Text;
                font-weight: 600;
                font-size: 24px;
                color: #393B3E;
                line-height: 28px;
                margin-bottom: 30px;
                &.warn {
                    color: #E01C40;
                }
            }
            .line {
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 20px;
            }
            p {
                font-family: SF Pro Text, SF Pro Text;
                font-weight: 400;
                font-size: 20px;
                color: #393B3E;
                line-height: 30px;
                margin-bottom: 30px;
            }
            .delete-btn {
                display: block;
                width: 100%;
                height: 48px;
                background: #E01C40;
                border-color: #E01C40;
                border-radius: 8px;
                font-family: SF Pro Text, SF Pro Text;
                font-size: 20px;
                color: #FFFFFF;
            }
        }
    }
    .form-wrap{
        width: 440px;
        .ant-form-item{
            display: block !important;
        }
    }
    .ant-form-item-label{
        margin-bottom: 10px !important;
        padding: 0 !important;
    }
    .ant-form-vertical .ant-form-item-label > label{
        font-size: 16px !important;
        font-family: SF Pro Text-Regular, SF Pro Text !important;
        font-weight: 400 !important;
        color: #393B3E !important;
        line-height: 18px !important;    
    }
    h2 {
        font-family: SF Pro Text, SF Pro Text;
        font-weight: 400;
        font-size: 16px;
        color: #393B3E;
        line-height: 18px;
        margin-bottom: 10px;
    }
    .ant-form-item{
        margin-bottom: 30px;
    }
    .ant-input-affix-wrapper {
        height: 40px;
        border-radius: 8px !important;
    }
    .ant-input-prefix, .ant-input {
        font-family: SF Pro Text-Regular, SF Pro Text;
        font-weight: 400 !important;
        color: #393B3E !important;
    }
    .singin-btn {
        display: block;
        width: 100%;
        height: 48px;
        background-color: #167639 !important;
        border-color: #167639 !important;
        border-radius: 8px;
        font-family: SF Pro Text, SF Pro Text;
        font-size: 20px;
        color: #FFFFFF !important;
    }
    .resend-btn {
        display: block;
        width: 100%;
        height: 48px;
        line-height: 48px;
        background-color: transparent !important;
        border-color: transparent !important;
        border-radius: 8px;
        font-family: SF Pro Text, SF Pro Text;
        font-size: 20px;
        color: #393B3E !important;
        margin-top: 7px;
        box-shadow: none !important;
        outline: none !important;
        text-align: center;
        cursor: pointer;
        .resend-text {
            margin-left: 5px;
        }
    }
}
.codes-form{
    margin-bottom: 30px;
    .ant-form-item-label {
        display: none !important;
    }
    .codes-input{
        display: grid;
        grid-template-columns: 76px 76px 76px 76px 76px 76px;
        grid-template-areas: 'input1 input2 input3 input4 input5 input6';
        place-items: center;
        place-content: center;
        height: 120px;
        .customize-input {
            width: 76px;
            display: grid;
            place-items: center;
            &:nth-child(1) {
                grid-area: input1;
            }
            &:nth-child(2) {
                grid-area: input2;
            }
            &:nth-child(3) {
                grid-area: input3;
            }
            &:nth-child(4) {
                grid-area: input4;
            }
            &:nth-child(5) {
                grid-area: input5;
            }
            &:nth-child(6) {
                grid-area: input6;
            }
            .input {
                width: 58px;
                height: 72px;
                line-height: 70px;
                text-align: center;
                font-size: 22px;
                font-family: SF Pro Text, SF Pro Text;
                font-weight: 400;
                color: #483778;
                box-sizing: border-box;
                background-color: #fff;
                box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.04);
                border-radius: 8px;
                border: 1px solid #AFC1C2;
                box-shadow: none !important;
                &.ant-input-status-error{
                    border-color: #ff4d4f;
                }
            }
        }
    }
    
    .codes-input-four{
        display: grid;
        grid-template-columns: 114px 114px 114px 114px;
        grid-template-areas: 'input1 input2 input3 input4';
        place-items: center;
        place-content: center;
        height: 120px;
        .customize-input {
            width: 76px;
            display: grid;
            place-items: center;
            place-content: center;
            &:nth-child(1) {
                grid-area: input1;
            }
            &:nth-child(2) {
                grid-area: input2;
            }
            &:nth-child(3) {
                grid-area: input3;
            }
            &:nth-child(4) {
                grid-area: input4;
            }
            .input {
                width: 58px;
                height: 72px;
                line-height: 70px;
                text-align: center;
                font-size: 22px;
                font-family: SF Pro Text, SF Pro Text;
                font-weight: 400;
                color: #483778;
                box-sizing: border-box;
                background-color: #fff;
                box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.04);
                border-radius: 8px;
                border: 1px solid #AFC1C2;
                box-shadow: none !important;
                &.ant-input-status-error{
                    border-color: #ff4d4f;
                }
            }
        }
    }
}