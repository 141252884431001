.cos-wrap{
    width: 100%;
    box-sizing: border-box;
    padding: 0 130px 50px 130px;
    min-width: 1380px;
    h1{
        font-size: 30px;
        font-family: SF Pro Text-Medium;
        font-weight: 500;
        color: #393B3E;
        line-height: 35px;
        margin-bottom: 20px; 
        box-sizing: border-box;
        .search-box{
            float: right;
            width: 222px;
            .ant-input{
                outline: none;
                box-shadow: none;
                box-sizing: border-box;
                height: 48px;
                border-radius: 8px;
                border: 1px solid #393B3E;
                font-size: 16px;
                font-family: SF Pro Text-Regular, SF Pro Text;
                font-weight: 400;
                color: #393B3E;
                padding-left: 50px;
                background-repeat: no-repeat;
                background-position: 20px center;
                background-size: 20px 20px;
                background-image: url('../../assets/icon/base.png');
                &::placeholder{
                    color: #393B3E;
                }
            }
        }
        .switch-box{
            float: right;
            cursor: pointer;
            width: 222px;
            height: 48px;
            border-radius: 8px;
            border: 1px solid #393B3E;
            font-size: 16px;
            font-family: SF Pro Text-Regular, SF Pro Text;
            font-weight: 400;
            box-sizing: border-box;
            padding-left: 50px;
            margin-left: 20px;
            background-repeat: no-repeat;
            background-position: 20px center;
            background-size: 20px 20px;
            background-image: url('../../assets/icon/switch.png');
            line-height: 46px;
        }
    }
    .mar-btm-40{
        margin-bottom: 40px;
    }
    .table{
        background: #FFFFFF;
        box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 0 0 30px 0;
        margin-bottom: 30px;
        h3{
            box-sizing: border-box;
            padding-left: 20px;
            margin-bottom: 10px;
            font-size: 20px;
            font-family: SF Pro Text-Medium, SF Pro Text;
            font-weight: 500;
            color: #393B3E;
            line-height: 23px;
        }
        .ant-table table{table-layout: fixed !important;}
        .ant-table-thead > tr > th{
            background: #fff !important;
            padding: 20px 20px 10px 20px !important;
            font-size: 12px !important;
            font-family: SF Pro Text-Medium !important;
            font-weight: 500 !important;
            color: #A1A4B3 !important;
            line-height: 16px !important;
            border-top: none !important;
            border-bottom: 1px solid #E5E5E5 !important;
        }
        .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
            display: none !important;
        }
        .ant-table-tbody > tr > td{
            padding: 10px 20px !important;
            border: none !important;
            word-break: break-word !important;
            font-size: 16px !important;
            font-family: SF Pro Text-Regular !important;
            font-weight: 400 !important;
            color: #393B3E !important;
            line-height: 28px !important;
            background-color: #fff !important;
        }
        .ant-table-tbody > tr:nth-child(1) td{
            padding-top: 25px !important;
        }
        .ant-table-tbody > tr:nth-child(2n) td{
            background-color: #F8F8FA !important;
        }
        .ant-table-column-sorters{
            display: block !important;
        }
        .ant-table-column-sorter{
            margin-left: 6px !important;
            color: #A1A4B3 !important;
            position: relative;
            top: -2px;
        }
        .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{
            color: #393B3E !important;
        }
        .avatar-info{
            padding-left: 58px;
            box-sizing: border-box;
            min-height: 48px;
            position: relative;
            .avatar{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: top center;
                background-size: cover;
                position: absolute;
                left:0;
                top:0;
            }
            h2{
                font-size: 16px;
                font-family: SF Pro Text-Regular, SF Pro Text;
                font-weight: 400;
                color: #393B3E;
                line-height: 25px;
                word-break: break-word;
                margin-bottom: 2px;
            }
            p{
                font-size: 16px;
                font-family: SF Pro Text-Regular, SF Pro Text;
                font-weight: 400;
                color: #A1A4B3;
                line-height: 21px;
                margin-bottom: 0;
            }
        }
        .swith-btn{
            background-repeat: no-repeat;
            background-position: top center;
            background-size: contain;
            width: 32px;
            height: 20px;
            cursor: pointer;
            background-image: url('../icon/dark.png');
        }
        .active{
            background-image: url('../icon/light.png');
        }
    }
    .table2{
        .ant-table-column-sorters{
            .ant-table-column-title{
                display: inline-block !important;
                word-break: break-word;
            }
        }
        .ant-table-column-sorter{
            top: -4px;
        }
    }
}
